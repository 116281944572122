// sidebar setup
if ($('aside.sidebar').get(0)) {

    (function() {
        var favourites = [],
            promotions = [],
            isLoggedIn = (window.isLoggedIn === '1' ? true : false),
            providerMap = window.providerMap2Name;
        var jqueryMap = {
            $gameContainer: $('aside.sidebar li.slot2 div.content-long-content div.row'),
            $promotionContainer: $('aside.sidebar li.slot4 div.content-long-content div.row'),
            $memberLink: $('aside.sidebar li.slot1 span.content-short'),
            $downloadLink: $('aside.sidebar li.slot5 span.content-short'),
            $depositLink: $('aside.sidebar li.slot3 span.content-short'),
            $langSet: $('aside.sidebar li.slot7 div.content-long-content div.row div')
        }


        promotions = [{
            name: 'NETNET老虎机新纪元'
        }, {
            name: '老人与海成就传奇'
        }, {
            name: '新会员注册存款即送'
        }, {
            name: '老虎机第一笔每天都送50%'
        }];

        jqueryMap.$langSet.on('click', function(event) {
            event.preventDefault();
            $.ajax({
                method: 'post',
                url: '/setLang',
                dataType: 'text',
                data: {lang: $(this).data('lang')},
                success: function(data) {
                    window.location.reload();
                }
            });
        });


        jqueryMap.$promotionContainer.empty();
        for (var i = 0; i < promotions.length; i++) {
            jqueryMap.$promotionContainer.append($('<div>').addClass('col-xs-12' + (i % 2 == 0 ? ' mod-inversed' : ''))
                .append($('<span>').addClass('list-styler').text(i + 1))
                .append( $('<span>').addClass('list-gameName').text(promotions[i].name)));
        }


        jqueryMap.$gameContainer.empty();
        if (isLoggedIn) {
            $.ajax({
                method: 'get',
                url: '/member/favGame?device=flash',
                dataType: 'json',
                success: function(data) {
                    if (Array.isArray(data)) {
                        favourites = data;
                        for (var i = 0; i < favourites.length; i++) {
                            jqueryMap.$gameContainer.append($('<div>').addClass('col-xs-6' + ((i % 4 == 2 || i % 4 == 3) ? ' mod-inversed' : ''))
                                .append($('<span>').addClass('list-styler').text(i + 1))
                                .append($('<span>').addClass('list-gameName').data({ 'gameid': favourites[i].gameID, 'code': favourites[i].code, 'providerid': favourites[i].provider_id }).text(favourites[i].name))
                                .append($('<span>').addClass('list-gameProvider').text(providerMap[favourites[i].provider_id]))
                                .append($('<span>').addClass('list-deleteIcon').data('id', favourites[i].id).text('X')));
                        }
                    }
                }
            });
        }

        // event handlers
        jqueryMap.$gameContainer
            .on('click', 'span.list-gameName', function() {
                var tmp_data = $(this).data(),
                    tmp_providerName = providerMap[tmp_data.providerid];
                var tmp_param = {
                    gameID: tmp_data.gameid,
                    code: tmp_data.code,
                    provider_id: tmp_data.providerid,
                    provider: tmp_providerName
                }

                window.open('/utility/launchGame/' + tmp_providerName + '/' + $.param(tmp_param), '_blank');

            })
            .on('click', 'span.list-deleteIcon', function() {
                var that = this;
                $.ajax({
                    method: 'post',
                    url: '/member/toggleLikeGame',
                    dataType: 'text',
                    data: {
                        game_id: $(that).data('id')
                    },
                    success: function(message) {
                        $(that).closest('div.col-xs-6').remove();
                    }
                })

            })

        jqueryMap.$memberLink.on('click', function() {
            window.open('/member', '_blank');
        })
        jqueryMap.$depositLink.on('click', function() {
            window.open('/member', '_blank');
        })
        jqueryMap.$downloadLink.on('click', function() {
            location.href = '/download';
        })


    })();

}
